import fire from "gatsby-plugin-firebase"
import { getAuth } from './initFirebase';
import DirectusSDK from '@directus/sdk-js';
import { AppUser } from "../data/app-user-model";

export const apiUrl = process.env.DIRECTUS_API_URL;
export const adminEmail = process.env.ADMIN_EMAIL;
export const adminPassword = process.env.ADMIN_PASSWORD;
export const adminAccessToken = process.env.ADMIN_ACCESS_TOKEN;

class MemoryStore {
    values;

    constructor() {
      this.values = {};
    }
    getItem(key) {
      return this.values[key];
    }
    setItem(key, value) {
      return this.values[key] = value;
    }
}
  
const directus = new DirectusSDK(apiUrl, {
    auth: {
		mode: 'json', // What login mode to use. One of `json`, `cookie`
        autoRefresh: true
    }
});
const customerRoleId = 'f0a79efd-5854-40b3-9eec-9f0bd4479547';

export const testUser = {username: 'swizzlej@hotmail.com', pword: 'random@2018_nathCorner'}

export const signOut = () => {
    const auth = getAuth();
    if (auth) {
        return auth.signOut();
    }
}

export const signIn = (email: string, password: string) => {
    const credentials = { email: email, password: password };
    return directus.auth.login(credentials)
    // const auth = getAuth();
    // if (auth) {
    //     return auth.signInWithEmailAndPassword(email, password);
    // }
}

export const refresh = () => {
    return directus.auth.refresh()
    // const auth = getAuth();
    // if (auth) {
    //     return auth.signInWithEmailAndPassword(email, password);
    // }
}

export const getCurrentUser  = () => {
    return directus.users.me.read()
}

export const getCurrentUserk = (id: string) => {
    const token = sessionStorage.getItem('ACCESS_TOKEN');
    const query: any =  {
        access_token: token
    };

    return directus.users.read(id, query);
}

export const storeAccessToken = (token: string) => {
    sessionStorage.setItem('ACCESS_TOKEN', token);
    localStorage.setItem('GCTECH_USER_LOGGED_IN', 'true');
}

export const refreshAccessToken = () => {
    const isLoggedIn = localStorage.getItem('GCTECH_USER_LOGGED_IN');
    if (isLoggedIn && isLoggedIn === 'true') {
        directus.auth.refresh()
    }
}

export const sendPWordReset = (email: string) => {
    const auth = getAuth();
    if (auth) {
        return auth.sendPasswordResetEmail(email);
    }
}

export const signUp = (user: AppUser) => {
    const query: any = { access_token: adminAccessToken };

    return directus.users.create(user, query);
}