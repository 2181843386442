import fire from "gatsby-plugin-firebase"

export const getFirestore = () => {
    if (typeof window !== 'undefined') {
        return fire.firestore();
    }

    return null;
}

export const getAuth = () => {
    if (typeof window !== 'undefined') {
        return fire.auth();
    }

    return null;
}

export const getStorage = () => {
    if (typeof window !== 'undefined') {
        return fire.storage();
    }

    return null;
}

export const getTimeStamp = () => {
    if (typeof window !== 'undefined') {
        return fire.firestore.Timestamp;
    }

    return null;
}